import { apiService } from '../../../../services/api-service';
import { getStaticAssetPath } from '../../../../utils/assets';

export class CodeCanvasUtils {
  static S3_PREFIX = 'spark';
  static CODE_FILENAME = 'index.html';

  static GetCodeS3Url(
    blockId: string,
    generationEndTime: string | null
  ): string | null {
    if (!generationEndTime) {
      return null;
    }
    const path = getStaticAssetPath(
      `${this.S3_PREFIX}/${blockId}/${this.CODE_FILENAME}`
    );
    return `${path}?_t=${generationEndTime}`;
  }

  static async UploadCode(blockId: string, code: string) {
    const blob = new Blob([code], { type: 'text/html' });
    await apiService.block.uploadSparkifactFile(blockId, blob, {
      filename: CodeCanvasUtils.CODE_FILENAME,
      contentType: 'text/html',
    });
  }
}
