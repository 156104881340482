import {
  DIALOGUE_MARKER_TYPES,
  DialogueMarker,
  DialogueScriptSegment,
} from './types';
import { Node, DOMParser } from '@lp-lib/dom-parser';

// NOTE: this library must reference lib.dom.ts in the tsconfig in order for
// dom-parser to be able to "link" against the dom types. Otherwise the entire
// library is `any`. But this also means that there appear to be globals in
// scope that are not actually. For example referencing `Node` (from the dom)
// will typecheck, but is not actually present unless imported from dom-parser!
// You may need to add new exports from dom-parser in order to properly
// reference them here. I have tried _everything_ to avoid this situation, but
// unfortunately it seems to be a limitation of TS itself. It cannot bundle lib
// types, nor re-export them.

export function fromString(script: string) {
  const segments: DialogueScriptSegment[] = [];

  const parser = new DOMParser();
  const doc = parser.parseFromString(script, 'text/html');

  const walkNodes = (nodes: NodeList) => {
    Array.from(nodes).forEach((node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        const textContent = node.textContent || '';
        if (textContent.trim()) {
          segments.push({ type: 'text', text: textContent });
        }
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        const element = node as HTMLElement;
        if (element.tagName.toLowerCase() === 'mark') {
          const type = element.getAttribute('type') || 'trigger';
          const name = element.getAttribute('name') || '';

          if (type === 'image') {
            const query = element.getAttribute('query') || '';
            segments.push({ type: 'image', name, query });
          } else if (type === 'trigger') {
            segments.push({ type: 'trigger', name });
          } else if (type === 'tutor-question') {
            const question = element.getAttribute('question') || '';
            const finishCriteria = element.getAttribute('finishCriteria') || '';
            segments.push({
              type: 'tutor-question',
              name,
              question,
              finishCriteria,
            });
          }
        }
        // Recursively process child nodes
        if (element.childNodes.length > 0) {
          walkNodes(element.childNodes);
        }
      }
    });
  };

  walkNodes(doc.body.childNodes);
  return segments;
}

export function fromScriptSegments(segments: DialogueScriptSegment[]) {
  return segments
    .map((segment) => {
      if (segment.type === 'text') {
        return segment.text;
      } else if (segment.type === 'image') {
        return `<mark type="image" name="${segment.name}" query="${segment.query}"></mark>`;
      } else if (segment.type === 'trigger') {
        return `<mark type="trigger" name="${segment.name}"></mark>`;
      }
      return '';
    })
    .join('');
}

function isDialogueMarker(
  segment: DialogueScriptSegment
): segment is DialogueMarker {
  return (
    segment.type !== 'text' && DIALOGUE_MARKER_TYPES.includes(segment.type)
  );
}

export function getDialogueMarkers(script: string): DialogueMarker[] {
  const segments = fromString(script);
  return segments.filter(isDialogueMarker);
}
