import shuffle from 'lodash/shuffle';
import { useMemo } from 'react';
import { useEffectOnce } from 'react-use';
import { proxy } from 'valtio';

import {
  type DtoTTSRenderRequest,
  EnumsTTSCacheControl,
  EnumsTTSRenderPolicy,
} from '@lp-lib/api-service-client/public';
import type { MemoryMatchBlock } from '@lp-lib/game';
import { type Logger } from '@lp-lib/logger-base';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { apiService } from '../../../../services/api-service';
import { sleep } from '../../../../utils/common';
import { markSnapshottable, useSnapshot } from '../../../../utils/valtio';
import { getStingerPrompt } from '../../apis/StingerControl';
import { BlockContainer } from '../../design/BlockContainer';
import {
  CommonButton,
  type CommonButtonVariant,
  GamePlayButton,
} from '../../design/Button';
import { CorrectAnimationWithLayout } from '../../design/CorrectAnimation';
import { SparkBlockBackground } from '../../design/SparkBackground';
import {
  type BlockDependencies,
  type IBlockCtrl,
  type PlaygroundPlaybackProtocol,
} from '../../types';
import { getOutputSchema, type MemoryMatchBlockOutputSchema } from './outputs';

type State = {
  status: 'init' | 'present' | 'play' | 'inform' | 'complete';
  visibility: 'visible' | 'hidden';
  cardState: Record<
    string,
    {
      status: 'default' | 'selected' | 'incorrect' | 'correct' | 'matched';
      text: string;
    }
  >;
  left: string[];
  right: string[];
  selected: [string | null, string | null];
  matched: string[];
  showCorrectAnimation: boolean;
};

type MismatchInfo = {
  leftText: string;
  rightText: string; // what they tried to match with leftText
  expectedRightText: string; // what leftText should have matched with
  frequency: number;
};

export class MatchBlockControlAPI implements IBlockCtrl {
  private _state = markSnapshottable(
    proxy<State>({
      status: 'init',
      visibility: 'hidden',
      cardState: {},
      left: [],
      right: [],
      selected: [null, null],
      matched: [],
      showCorrectAnimation: false,
    })
  );
  private matchLookup = new Map<string, string>();
  private mismatchCount = 0;
  private mismatches = new Map<string, MismatchInfo>();
  private resolvedTTS: {
    stinger: Nullable<DtoTTSRenderRequest>;
    prompt: Nullable<DtoTTSRenderRequest>;
    completion: Nullable<DtoTTSRenderRequest>;
  } = {
    stinger: null,
    prompt: null,
    completion: null,
  };
  private delegate: Nullable<PlaygroundPlaybackProtocol>;
  private logger: Logger;
  private schema: MemoryMatchBlockOutputSchema;

  constructor(
    private block: MemoryMatchBlock,
    private deps: BlockDependencies
  ) {
    this.logger = deps.getLogger('match-block');
    this.schema = getOutputSchema(block);
  }

  get state() {
    return this._state;
  }

  async preload() {
    if (!this.block.fields.personalityId) return;
    this.resolvedTTS.stinger = await this.stingerTTS();
    this.resolvedTTS.prompt = await this.promptTTS();
    await this.deps.lvoLocalCacheWarm(this.resolvedTTS.stinger);
    await this.deps.lvoLocalCacheWarm(this.resolvedTTS.prompt);
  }

  async initialize(preloaded: Promise<void>) {
    await preloaded;

    const left = [];
    const right = [];
    const matchLookup = new Map<string, string>();
    const cards = this.block.fields.cardPairs ?? [];
    const cardState: State['cardState'] = {};
    for (let i = 0; i < cards.length; i++) {
      const card = cards[i];
      const leftId = `l:${i}`;
      const rightId = `r:${i}`;
      cardState[leftId] = {
        status: 'default',
        text: card.firstText ?? '',
      };
      cardState[rightId] = {
        status: 'default',
        text: card.secondText ?? '',
      };
      left.push(leftId);
      right.push(rightId);
      matchLookup.set(leftId, rightId);
      matchLookup.set(rightId, leftId);
    }
    this._state.left = shuffle(left);
    this._state.right = shuffle(right);
    this._state.cardState = cardState;
    this.matchLookup = matchLookup;
  }

  setDelegate(delegate: PlaygroundPlaybackProtocol) {
    this.delegate = delegate;
  }

  private updateTutorForBlockStatus(status: State['status']) {
    switch (status) {
      case 'play':
      case 'complete':
        this.deps.tutorControl.setAvailabilityState('available');
        break;
      case 'inform':
        this.deps.tutorControl.setAvailabilityState('unavailable');
        break;
      default:
        this.deps.tutorControl.setAvailabilityState('unavailable');
    }
  }

  async present() {
    try {
      await this.deps.stingerControl.playBlockIntro(
        this.block,
        this.resolvedTTS.stinger
      );
    } catch (e) {
      this.logger.error('failed to play stinger TTS', e);
    }

    try {
      const player = this.deps.createLVOLocalPlayer(this.resolvedTTS.prompt);
      const info = await player.playFromPool();
      await info?.trackStarted;
      this._state.visibility = 'visible';
      this._state.status = 'present';
      this.updateTutorForBlockStatus('present');
      await info?.trackEnded;
    } catch (e) {
      this.logger.error(
        'failed to play question TTS, falling back to silence',
        e
      );
      this._state.visibility = 'visible';
      this._state.status = 'present';
      this.updateTutorForBlockStatus('present');
      await sleep(3000);
    }
    this._state.status = 'play';
    this.updateTutorForBlockStatus('play');
  }

  select(col: 'left' | 'right', id: string) {
    const index = col === 'left' ? 0 : 1;
    if (this._state.selected[index] === id) {
      this._state.selected[index] = null;
      this._state.cardState[id].status = 'default';
    } else {
      const current = this._state.selected[index];
      if (current) {
        this._state.cardState[current].status = 'default';
      }

      this._state.selected[index] = id;
      this._state.cardState[id].status = 'selected';
    }
    this.tryMatch();
  }

  private trackMismatch(leftId: string, rightId: string) {
    this.mismatchCount += 1;

    const leftText = this._state.cardState[leftId].text;
    const rightText = this._state.cardState[rightId].text;
    const correctPairId = this.matchLookup.get(leftId);

    if (correctPairId) {
      const expectedRightText = this._state.cardState[correctPairId].text;
      // Create a consistent key regardless of order
      const key = [leftText, rightText].sort().join('|||');
      const existing = this.mismatches.get(key);
      if (existing) {
        existing.frequency += 1;
      } else {
        this.mismatches.set(key, {
          leftText,
          rightText,
          expectedRightText,
          frequency: 1,
        });
      }
    } else {
      this.logger.error('No matching pair found for left card', leftId);
    }
  }

  tryMatch() {
    const [left, right] = this._state.selected;
    if (left && right) {
      const isMatch = this.matchLookup.get(left) === right;
      if (isMatch) {
        this._state.matched.push(left, right);
        (async () => {
          this._state.cardState[left].status = 'correct';
          this._state.cardState[right].status = 'correct';
          this.deps.sfxControl.play('rapidCorrect');
          this.deps.shakeControl.rootPop();
          await sleep(400);
          this._state.cardState[left].status = 'matched';
          this._state.cardState[right].status = 'matched';
        })();
        if (this._state.matched.length === this._state.left.length * 2) {
          this.inform();
        }
      } else {
        (async () => {
          this.trackMismatch(left, right);
          this._state.cardState[left].status = 'incorrect';
          this._state.cardState[right].status = 'incorrect';
          this.deps.sfxControl.play('rapidWrong');
          this.deps.shakeControl.rootIncorrect();
          await sleep(400);
          this._state.cardState[left].status = 'default';
          this._state.cardState[right].status = 'default';
        })();
      }
      this._state.selected = [null, null];
    }
  }

  async inform() {
    this.commitOutput();
    this._state.showCorrectAnimation = true;
    if (this.block.fields.pointsPerMatch === 0) {
      this._state.status = 'complete';
      this.updateTutorForBlockStatus('complete');
      return;
    }
    this._state.status = 'inform';
    this.updateTutorForBlockStatus('inform');
    if (!this.deps.isAssessing()) {
      try {
        this.resolvedTTS.completion = await this.completionTTS();
        const player = this.deps.createLVOLocalPlayer(
          this.resolvedTTS.completion
        );
        const info = await player.playFromPool();
        await info?.trackEnded;
      } catch (e) {
        this.logger.error('failed to play result TTS', e);
      }
    }
    this._state.status = 'complete';
    this.updateTutorForBlockStatus('complete');
  }

  private commitOutput() {
    this.delegate?.blockDidOutput(this.schema.question, this.block.fields.text);
    this.delegate?.blockDidOutput(
      this.schema.cardPairsCount,
      this._state.left.length
    );
    this.delegate?.blockDidOutput(
      this.schema.matchedCount,
      this._state.matched.length / 2
    );
    this.delegate?.blockDidOutput(
      this.schema.points,
      (this._state.matched.length / 2) * this.block.fields.pointsPerMatch
    );
    this.delegate?.blockDidOutput(
      this.schema.totalPoints,
      this._state.left.length * this.block.fields.pointsPerMatch
    );
    this.delegate?.blockDidOutput(
      this.schema.mismatchCount,
      this.mismatchCount
    );
  }

  async end() {
    this.deps.sfxControl.play('instructionHoverReadyButton');
    await this.delegate?.blockDidEnd();
  }

  async destroy() {
    return;
  }

  private async stingerTTS(): Promise<Nullable<DtoTTSRenderRequest>> {
    if (!this.deps.stingerControl.shouldPreloadTTS(this.block)) return null;

    return this.makeTTSRenderRequest(
      getStingerPrompt(
        this.block.fields.text,
        "Match'em Up",
        'Quickly match pairs to win.'
      )
    );
  }

  private async promptTTS() {
    return this.makeTTSRenderRequest(this.block.fields.text);
  }

  private async completionTTS(): Promise<Nullable<DtoTTSRenderRequest>> {
    return this.makeTTSRenderRequest(await this.getEvaluationScript(), true);
  }

  private async makeTTSRenderRequest(
    script: Nullable<string>,
    preferFlashRender = false
  ): Promise<Nullable<DtoTTSRenderRequest>> {
    if (!script) return null;

    const personalityId = this.block.fields.personalityId;
    if (!personalityId) return null;

    const resolved = await this.deps.commonVariableRegistry.render(script);
    return {
      script: resolved.script,
      personalityId,
      cacheControl: EnumsTTSCacheControl.TTSCacheControlShortLive,
      policy: EnumsTTSRenderPolicy.TTSRenderPolicyReadThrough,
      preferFlashRender,
    };
  }

  private async getEvaluationScript(): Promise<Nullable<string>> {
    if (!this.block.fields.personalityId) return null;

    try {
      const resp = await apiService.promptTemplate.runTemplate({
        promptTemplateMappingKey: 'match/evaluation-script',
        variables: {
          context: this.block.fields.text,
          mismatches: Array.from(this.mismatches.values()).sort(
            (a, b) => b.frequency - a.frequency
          ),
          mismatchCount: this.mismatchCount,
        },
      });
      return resp.data.content;
    } catch (e) {
      this.logger.error('failed to generate evaluation script', e);
      return null;
    }
  }
}

export function MatchBlockPlayground(props: {
  block: MemoryMatchBlock;
  ctrl: MatchBlockControlAPI;
}) {
  const { text: prompt } = props.block.fields;
  const { status, visibility, left, right, showCorrectAnimation } = useSnapshot(
    props.ctrl.state
  );

  useEffectOnce(() => {
    props.ctrl.present();
  });

  return (
    <>
      <SparkBlockBackground block={props.block} />
      <BlockContainer className='flex flex-col'>
        <main
          className={`
          w-full flex-1 min-h-0 px-10 flex flex-col justify-center gap-5 sm:gap-10
          transition-opacity duration-500 ${
            visibility === 'visible' ? 'opacity-100' : 'opacity-0'
          }
        `}
        >
          <div className='text-white text-center break-words text-base sm:text-xl md:text-2xl lg:text-3xl'>
            {prompt}
          </div>

          <div className='w-full flex gap-2'>
            <div className='w-1/2 min-w-0 flex flex-col gap-2'>
              {left.map((id) => (
                <MatchButton key={id} id={id} col='left' ctrl={props.ctrl} />
              ))}
            </div>
            <div className='w-1/2 min-w-0 flex flex-col gap-2'>
              {right.map((id) => (
                <MatchButton key={id} id={id} col='right' ctrl={props.ctrl} />
              ))}
            </div>
          </div>
        </main>

        <footer
          className={`
          w-full px-3 pt-3 pb-5 flex justify-center relative
          transition-opacity duration-500 ${
            visibility === 'visible' ? 'opacity-100' : 'opacity-0'
          }
        `}
        >
          <CommonButton
            variant='correct'
            onClick={() => props.ctrl.end()}
            className={
              status === 'inform' || status === 'complete'
                ? 'opacity-100'
                : 'opacity-0 pointer-events-off'
            }
            disabled={status === 'inform'}
          >
            Continue
          </CommonButton>
          {showCorrectAnimation && props.block.fields.pointsPerMatch > 0 && (
            <CorrectAnimationWithLayout onEnd={() => void 0} />
          )}
        </footer>
      </BlockContainer>
    </>
  );
}

function MatchButton(props: {
  id: string;
  col: 'left' | 'right';
  ctrl: MatchBlockControlAPI;
}) {
  const { id, col, ctrl } = props;
  const { status, text } = useSnapshot(ctrl.state).cardState[id];

  const [variant, disabled]: [CommonButtonVariant, boolean] = useMemo(() => {
    switch (status) {
      case 'selected':
        return ['brand', false];
      case 'incorrect':
        return ['incorrect', true];
      case 'correct':
        return ['correct', true];
      case 'matched':
        return ['gray', true];
      default:
        return ['gray', false];
    }
  }, [status]);

  const handleClick = useLiveCallback(() => {
    props.ctrl.select(col, id);
  });

  return (
    <GamePlayButton
      variant={variant}
      styles={{
        size: 'w-full h-18',
        spacing: 'px-2 py-1',
      }}
      disabled={disabled}
      onClick={handleClick}
      className={`${status !== 'matched' ? 'disabled:opacity-100' : ''}`}
    >
      <span className='w-full text-xs font-bold text-white break-words'>
        {text}
      </span>
    </GamePlayButton>
  );
}
