import { useCallback, useEffect, useRef, useState } from 'react';
import { proxy } from 'valtio';

import {
  type DtoTTSRenderRequest,
  EnumsJeopardyTurnResult,
  EnumsTTSCacheControl,
  EnumsTTSRenderPolicy,
} from '@lp-lib/api-service-client/public';
import {
  Delimited,
  type QuestionBlock,
  QuestionBlockAnswerGrade,
} from '@lp-lib/game';
import { type Logger } from '@lp-lib/logger-base';

import { apiService } from '../../../../services/api-service';
import { markSnapshottable, useSnapshot } from '../../../../utils/valtio';
import {
  createCommonPromptTemplateGrader,
  GraderPipeline,
  SimpleGrader,
} from '../../apis/graders/AIGrader';
import { type Grader } from '../../apis/graders/types';
import { JeopardyHeader, JeopardyTools } from '../../apis/JeopardyControl';
import { getStingerPrompt } from '../../apis/StingerControl';
import { Timer, TimerBackground } from '../../apis/Timer';
import { BlockContainer } from '../../design/BlockContainer';
import { CommonButton } from '../../design/Button';
import { CorrectAnimationWithLayout } from '../../design/CorrectAnimation';
import { CommonInput } from '../../design/Input';
import { SparkBlockBackground } from '../../design/SparkBackground';
import {
  type BlockDependencies,
  type IBlockCtrl,
  type PlaygroundPlaybackProtocol,
} from '../../types';
import { getOutputSchema, type QuestionBlockOutputSchema } from './outputs';

function createQuestionGrader(block: QuestionBlock): Grader {
  const correctAnswers = [
    block.fields.answer,
    ...new Delimited().parse(block.fields.additionalAnswers || ''),
  ]
    .map((a) => a?.trim().toLowerCase())
    .filter(Boolean);

  const graders: Grader[] = [];
  graders.push(new SimpleGrader(correctAnswers));
  graders.push(
    createCommonPromptTemplateGrader({
      context: block.fields.question,
      correctAnswers: correctAnswers.slice(0, 3),
    })
  );
  return new GraderPipeline(graders);
}

type State = {
  status: 'init' | 'present' | 'play' | 'grade' | 'inform' | 'complete';
  questionVisibility: 'hidden' | 'entering' | 'entered';
  questionHasEntered: ReturnType<typeof Promise.withResolvers<void>>;
  inputVisibility: 'visible' | 'hidden';
  inputEnabled: boolean;
  cta: 'submit' | 'complete';
  submission?: string;
  result?: 'correct' | 'incorrect' | 'skipped' | null;
  continueEnabled: boolean;
};

export class QuestionBlockControlAPI implements IBlockCtrl {
  private _state = markSnapshottable(
    proxy<State>({
      status: 'init',
      questionVisibility: 'hidden',
      questionHasEntered: Promise.withResolvers(),
      inputVisibility: 'hidden',
      inputEnabled: false,
      cta: 'submit',
      continueEnabled: false,
    })
  );
  private resolvedTTS: {
    stinger: Nullable<DtoTTSRenderRequest>;
    question: Nullable<DtoTTSRenderRequest>;
    correct: Nullable<DtoTTSRenderRequest>;
    incorrect: Nullable<DtoTTSRenderRequest>;
    skipped: Nullable<DtoTTSRenderRequest>;
  } = {
    stinger: null,
    question: null,
    correct: null,
    incorrect: null,
    skipped: null,
  };
  private delegate: Nullable<PlaygroundPlaybackProtocol>;
  private logger: Logger;
  private grader: Grader;
  private schema: QuestionBlockOutputSchema;
  private readonly willGrade: boolean;
  private _timer: Nullable<Timer>;
  private _aborter = new AbortController();

  constructor(private block: QuestionBlock, private deps: BlockDependencies) {
    this.logger = deps.getLogger('question-block');
    this.grader = createQuestionGrader(block);
    this.schema = getOutputSchema(block);
    this.willGrade = block.fields.points !== 0;
    if (this.deps.jeopardyControl) {
      this._timer = new Timer(block.fields.time * 1000);
    }
  }

  get state() {
    return this._state;
  }

  get timer() {
    return this._timer;
  }

  get jeopardyControl() {
    return this.deps.jeopardyControl;
  }

  private async sleep(ms: number) {
    // TODO: make this a utility that will be assigned to the iblockctrl at
    // construction time, such as this.sleep = createIBlockCtrlSleep(aborter)
    await new Promise((resolve, reject) => {
      const timeoutId = setTimeout(resolve, ms);

      this._aborter.signal?.addEventListener(
        'abort',
        () => {
          clearTimeout(timeoutId);
          reject(new Error('Sleep aborted'));
        },
        { once: true }
      );
    });
  }

  async preload() {
    if (this.deps.stingerControl.shouldPreloadTTS(this.block)) {
      this.resolvedTTS.stinger = await this.preloadTTS(
        getStingerPrompt(
          this.block.fields.question,
          'Quick Q',
          'A short open-ended question.'
        )
      );
    }
    this.resolvedTTS.question = await this.preloadTTS(
      this.block.fields.question
    );

    // preload TTS for grading, but do not wait it
    this.preloadGradingTTS();

    await Promise.all([
      this.deps.sfxControl.preload('pop'),
      this.deps.sfxControl.preload('baDink'),
      this.deps.sfxControl.preload('instructionHoverReadyButton'),
      this.deps.sfxControl.preload('rapidCorrect'),
      this.deps.sfxControl.preload('rapidWrong'),
    ]);

    this.deps.sfxControl.preload('timePaused');
    this.deps.sfxControl.preload('jeopardyTimesUp');
  }

  private async preloadGradingTTS() {
    if (!this.willGrade) return null;

    this.resolvedTTS.correct = await this.preloadTTS(
      await this.getEvaluationScript(
        this.block.fields.question,
        this.block.fields.answer,
        this.block.fields.answer,
        'correct'
      )
    );

    if (this.deps.jeopardyControl) {
      this.resolvedTTS.skipped = await this.preloadTTS(
        await this.getEvaluationScript(
          this.block.fields.question,
          this.block.fields.answer,
          '<No submission>',
          'skipped'
        )
      );
    }
  }

  async initialize(preloaded: Promise<void>) {
    await preloaded;
  }

  setDelegate(delegate: PlaygroundPlaybackProtocol) {
    this.delegate = delegate;
  }

  private updateTutorForBlockStatus(status: State['status']) {
    if (this.deps.jeopardyControl) {
      this.deps.tutorControl.setAvailabilityState('unavailable');
      return;
    }

    switch (status) {
      case 'play':
      case 'complete':
        this.deps.tutorControl.setAvailabilityState('available');
        break;
      case 'grade':
      case 'inform':
        this.deps.tutorControl.setAvailabilityState('unavailable');
        break;
      default:
        this.deps.tutorControl.setAvailabilityState('unavailable');
    }
  }

  async present() {
    try {
      await this.deps.stingerControl.playBlockIntro(
        this.block,
        this.resolvedTTS.stinger
      );
    } catch (e) {
      this.logger.error('failed to play stinger TTS', e);
    }

    try {
      const player = this.deps.createLVOLocalPlayer(this.resolvedTTS.question);
      const info = await player.playFromPool();
      await info?.trackStarted;
      this._state.status = 'present';
      this.updateTutorForBlockStatus('present');
      this._state.questionVisibility = 'entering';
      this.deps.sfxControl.play('pop');
      await Promise.all([info?.trackEnded, this.sleep(2000)]);
    } catch (e) {
      this.logger.error(
        'failed to play question TTS, falling back to silence',
        e
      );
      this._state.status = 'present';
      this.updateTutorForBlockStatus('present');
      this._state.questionVisibility = 'entering';
      await this.sleep(3000);
    }

    this._state.questionVisibility = 'entered';
    await this._state.questionHasEntered.promise;
    this.deps.sfxControl.play('baDink');
    this._state.status = 'play';
    this.updateTutorForBlockStatus('play');
    this._state.inputVisibility = 'visible';
    this._state.inputEnabled = true;

    this._timer?.on('timeout', () => this.timeout());
    this._timer?.start();
  }

  async submitAnswer(submission: string) {
    this._timer?.stop();
    this._state.inputEnabled = false;

    this.deps.sfxControl.play('instructionHoverReadyButton');
    this._state.submission = submission;
    this.delegate?.blockDidOutput(
      this.schema.question,
      this.block.fields.question
    );
    this.delegate?.blockDidOutput(this.schema.answer, this.block.fields.answer);
    this.delegate?.blockDidOutput(this.schema.submission, submission);

    if (!this.willGrade) {
      this._state.status = 'complete';
      this.updateTutorForBlockStatus('complete');
      this._state.cta = 'complete';
      return this.delegate?.blockDidEnd();
    }

    this._state.status = 'grade';
    this.updateTutorForBlockStatus('grade');
    const grade = await this.grader.grade(submission);
    const correct = grade.grade === QuestionBlockAnswerGrade.CORRECT;
    const result = correct ? 'correct' : 'incorrect';
    this.delegate?.blockDidOutput(this.schema.grade, result);
    this.delegate?.blockDidOutput(
      this.schema.points,
      correct ? this.block.fields.points : 0
    );
    this.delegate?.blockDidOutput(
      this.schema.totalPoints,
      this.block.fields.points
    );

    if (result === 'correct') {
      this._state.result = result;
      this._state.status = 'inform';
      this.updateTutorForBlockStatus('inform');
      this._state.cta = 'complete';

      this.deps.shakeControl.rootPop();
      this.deps.sfxControl.play('rapidCorrect');
      if (!this.deps.isAssessing()) {
        await this.playTTS(this.resolvedTTS.correct);
      }
    } else {
      let incorrectReq: Nullable<DtoTTSRenderRequest> = null;
      if (!this.deps.isAssessing()) {
        try {
          const script = await this.getEvaluationScript(
            this.block.fields.question,
            this.block.fields.answer,
            submission,
            'incorrect'
          );
          incorrectReq = await this.makeTTSRenderRequest(script);
        } catch (e) {
          this.logger.error(
            'failed to generate incorrect answer tts request',
            e
          );
        }
      }

      // defer these steps so they appear with the sfx and vfx.
      this._state.result = result;
      this._state.status = 'inform';
      this.updateTutorForBlockStatus('inform');
      this._state.cta = 'complete';

      this.deps.shakeControl.rootIncorrect();
      this.deps.sfxControl.play('rapidWrong');
      if (incorrectReq) {
        incorrectReq.preferFlashRender = true;
        await this.playTTS(incorrectReq);
      }
    }

    this._state.status = 'complete';
    this.updateTutorForBlockStatus('complete');
    this._state.continueEnabled = true;
  }

  async skip() {
    this._timer?.stop();
    this._state.inputEnabled = false;

    this._state.result = 'skipped';
    this._state.status = 'inform';
    this.updateTutorForBlockStatus('inform');
    this._state.cta = 'complete';

    await this.playTTS(this.resolvedTTS.skipped);
    this._state.status = 'complete';
    this.updateTutorForBlockStatus('complete');
    this._state.continueEnabled = true;
  }

  pauseTime() {
    this.deps.sfxControl.play('timePaused');
    this._timer?.pause(10000);
  }

  async timeout() {
    this.deps.sfxControl.play('jeopardyTimesUp');
    await this.skip();
  }

  async end() {
    this._state.continueEnabled = false;
    this.deps.sfxControl.play('instructionHoverReadyButton');
    // Do not fully wait for sfx (it's almost 1s), but allow sfx to start before
    // possibly conflicting with the following block's POP
    await this.sleep(200);
    await this.delegate?.blockDidEnd();
    this.jeopardyControl?.end(
      this._state.result === 'correct'
        ? EnumsJeopardyTurnResult.JeopardyTurnResultCorrect
        : this._state.result === 'incorrect'
        ? EnumsJeopardyTurnResult.JeopardyTurnResultIncorrect
        : EnumsJeopardyTurnResult.JeopardyTurnResultSkipped
    );
  }

  async destroy() {
    this._timer?.stop();
    this._aborter.abort();
  }

  private async preloadTTS(script: Nullable<string>) {
    const req = await this.makeTTSRenderRequest(script);
    if (!req) return null;
    await this.deps.lvoLocalCacheWarm(req);
    return req;
  }

  private async playTTS(req: Nullable<DtoTTSRenderRequest>) {
    if (!req) return;

    try {
      const player = this.deps.createLVOLocalPlayer(req);
      const info = await player.playFromPool();
      await info?.trackEnded;
    } catch (e) {
      this.logger.error('failed to play TTS', e);
    }
  }

  private async makeTTSRenderRequest(
    script: Nullable<string>
  ): Promise<Nullable<DtoTTSRenderRequest>> {
    if (!script) return null;

    const personalityId = this.block.fields.personalityId;
    if (!personalityId) return null;

    const resolved = await this.deps.commonVariableRegistry.render(script);
    return {
      script: resolved.script,
      personalityId,
      cacheControl: EnumsTTSCacheControl.TTSCacheControlShortLive,
      policy: EnumsTTSRenderPolicy.TTSRenderPolicyReadThrough,
    };
  }

  private async getEvaluationScript(
    question: string,
    correctAnswer: string,
    submission: string,
    evaluation: string
  ): Promise<Nullable<string>> {
    if (!this.block.fields.personalityId) return null;

    try {
      const resp = await apiService.promptTemplate.runTemplate({
        promptTemplateMappingKey: 'question/evaluation-script',
        variables: {
          question,
          correctAnswer,
          submission,
          evaluation,
        },
      });
      return resp.data.content;
    } catch (e) {
      this.logger.error('failed to generate evaluation script', e);
      return null;
    }
  }
}

export function QuestionBlockPlayground(props: {
  block: QuestionBlock;
  ctrl: QuestionBlockControlAPI;
}) {
  const { question, answer } = props.block.fields;
  const {
    status,
    questionVisibility,
    inputVisibility,
    inputEnabled,
    result,
    cta,
    continueEnabled,
  } = useSnapshot(props.ctrl.state);

  const [submissionValue, setSubmissionValue] = useState('');
  useEffect(() => {
    if (status === 'init') {
      props.ctrl.present();
    }
  }, [status, props.ctrl]);

  const handleSubmit = useCallback(() => {
    props.ctrl.submitAnswer(submissionValue);
  }, [props.ctrl, submissionValue]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        handleSubmit();
      }
    },
    [handleSubmit]
  );

  const questionRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (!questionRef.current) return;

    if (questionVisibility === 'entering') {
      // transition to middle of screen vertically

      // NOTE: typically it should be fine to use window.innerHeight. However,
      // the TrainingEditor does two things:
      //
      // 1. previews using an iframe
      // 2. portals the content into the iframe
      //
      // Due to the use of the portal, `window` is actually not the iframe's
      // window! It is the portal owner's window! Therefore `window.innerHeight`
      // _in this code right here_ will not represent the visible
      // `window.innerHeight` of the iframe! Therefore, we have to get a
      // reference to the window of the iframe using an element from said
      // iframe.
      //
      // One other note is that measuring
      // questionRef.current.ownerDocument.body.getBoundingClientRect().height
      // also fails because the first child within _shell is defined as
      // `position: static` and therefore the body element has no height!
      const innerHeight =
        questionRef.current.ownerDocument.defaultView?.innerHeight ??
        window.innerHeight;
      const box = questionRef.current.getBoundingClientRect();
      const startY = innerHeight - box.y;
      const targetY = innerHeight / 2 - box.height / 2 - box.y;

      const anim = questionRef.current.animate(
        [
          { transform: `translateY(${startY}px)`, opacity: 0 },
          { transform: `translateY(${targetY}px)`, opacity: 1 },
        ],
        {
          duration: 300,
          easing: 'ease-in-out',
          fill: 'both',
        }
      );

      anim.play();
      anim.finished.then(() => anim.commitStyles());
      return () => {
        anim.cancel();
      };
    }

    if (questionVisibility === 'entered') {
      // transition to final (original) destination

      const anim = questionRef.current.animate(
        [{ transform: `translateY(0px)` }],
        {
          duration: 200,
          easing: 'ease-in-out',
          fill: 'both',
        }
      );

      anim.finished.then(() => {
        props.ctrl.state.questionHasEntered.resolve();
        anim.commitStyles();
      });

      anim.play();
      return () => {
        anim.cancel();
      };
    }
  }, [props.ctrl.state.questionHasEntered, questionVisibility]);

  return (
    <>
      <SparkBlockBackground block={props.block} />

      {props.ctrl.timer && <TimerBackground timer={props.ctrl.timer} />}

      <BlockContainer className='flex flex-col'>
        {props.ctrl.jeopardyControl && (
          <JeopardyHeader jeopardyControl={props.ctrl.jeopardyControl} />
        )}

        <main className='w-full flex-1 min-h-0 px-10 flex flex-col justify-center gap-5 sm:gap-10'>
          <div
            ref={questionRef}
            className={`
              text-white text-center break-words
              text-xl md:text-2xl lg:text-3xl
              ${questionVisibility === 'hidden' ? 'opacity-0' : ''}
            `}
          >
            <span
              style={{
                // These are key to the tiktok/instagram style text breaking
                // where each line has its own tight rounded background
                boxDecorationBreak: 'clone',
                WebkitBoxDecorationBreak: 'clone',
              }}
              className='
                px-1.5 py-0.5
                leading-relaxed
                rounded-md
                bg-black bg-opacity-80
              '
            >
              {question}
            </span>
          </div>

          <div
            className={`w-full flex flex-col items-center gap-2 transition-opacity duration-200 ${
              inputVisibility === 'visible' ? 'opacity-100' : 'opacity-0'
            }`}
          >
            <CommonInput
              type='text'
              placeholder='Type your answer here'
              value={submissionValue}
              onChange={(e) => setSubmissionValue(e.target.value)}
              disabled={!inputEnabled}
              onKeyDown={handleKeyDown}
              variant={
                result === 'correct'
                  ? 'correct'
                  : result === 'incorrect'
                  ? 'incorrect'
                  : 'brand'
              }
            />
            {props.ctrl.jeopardyControl && (
              <button
                type='button'
                className={`btn text-sms font-bold text-white`}
                disabled={!inputEnabled}
                onClick={() => props.ctrl.skip()}
              >{`Skip >>`}</button>
            )}
            <div
              className={`transition-opacity duration-500 ${
                result === 'incorrect' || result === 'skipped'
                  ? 'opacity-100'
                  : 'opacity-0'
              }`}
            >
              <div className='text-center text-green-001 font-bold text-xs'>
                Correct answer
              </div>
              <div className='text-center text-green-001 text-base'>
                {answer}
              </div>
            </div>
          </div>
        </main>

        <footer
          className={`'w-full flex flex-col items-center gap-2 px-3 pt-3 pb-5 transition-opacity duration-500 relative ${
            inputVisibility === 'visible' ? 'opacity-100' : 'opacity-0'
          }`}
        >
          {props.ctrl.jeopardyControl && (
            <JeopardyTools
              jeopardyControl={props.ctrl.jeopardyControl}
              onExtraTime={() => props.ctrl.pauseTime()}
              visible={status === 'play'}
            />
          )}

          <div className='w-full h-5 flex items-center justify-center text-xs text-black text-opacity-80 text-center'>
            {result === 'correct' ? (
              <span className='text-green-001'>
                <strong>Correct!</strong> Nice job!
              </span>
            ) : result === 'incorrect' ? (
              <span className='text-red-006'>
                <strong>Incorrect!</strong> See correct answer above.
              </span>
            ) : result === 'skipped' ? (
              <span className='text-yellow-001'>
                <strong>Skipped!</strong> See correct answer above.
              </span>
            ) : null}
          </div>
          {cta === 'submit' ? (
            <CommonButton
              variant='brand'
              onClick={handleSubmit}
              disabled={status !== 'play' || submissionValue === ''}
            >
              Submit
            </CommonButton>
          ) : (
            <CommonButton
              variant={
                result === 'correct'
                  ? 'correct'
                  : result === 'incorrect'
                  ? 'incorrect'
                  : 'brand'
              }
              onClick={() => props.ctrl.end()}
              disabled={!continueEnabled}
            >
              Continue
            </CommonButton>
          )}
          {result === 'correct' && (
            <CorrectAnimationWithLayout onEnd={() => void 0} />
          )}
        </footer>
      </BlockContainer>
    </>
  );
}
