import isEqualWith from 'lodash/isEqualWith';

import {
  ClientAspectRatio,
  type DtoPersonality,
  EnumsDialogueGenerationType,
  type ModelsDialogue,
  type ModelsDialogueEntry,
} from '@lp-lib/api-service-client/public';
import {
  fromScriptSegments,
  fromString,
  getDialogueMarkers,
} from '@lp-lib/dialogue';
import { type Media } from '@lp-lib/media';
import { trainingDialogueSchema } from '@lp-lib/shared-schema/src/ai/functions/zod/trainingDialogue';

import { apiService } from '../../../services/api-service';
import { uuidv4 } from '../../../utils/common';
import {
  type DialogueMarker,
  type DialogueMarkerImage,
  type DialogueScriptSegment,
} from './types';

const emojiMap: string[] = [
  '',
  '1️⃣',
  '2️⃣',
  '3️⃣',
  '4️⃣',
  '5️⃣',
  '6️⃣',
  '7️⃣',
  '8️⃣',
  '9️⃣',
  '🔟',
];

export class DialogueUtils {
  static ParseMarkers(script: string): DialogueMarker[] {
    return getDialogueMarkers(script);
  }

  static GetTriggerDisplayName(trigger: string): Nullable<string> {
    const match = trigger.match(/^trigger-(\d+)$/);
    if (match) {
      const number = parseInt(match[1], 10);
      if (number >= 1 && number <= 10) {
        return emojiMap[number];
      }
    }
    return null;
  }

  static DialogueToAISchema(dialogue: ModelsDialogue) {
    return {
      entries: dialogue.entries.map((entry) => ({
        script: entry.script,
        speakerId: entry.personalityId,
      })),
    };
  }

  static AISchemaToDialogue(args: unknown): ModelsDialogue {
    const schema = trainingDialogueSchema.parse(args);
    const dialogue = {
      entries: schema.entries.map((entry) => {
        return {
          id: uuidv4(),
          generationType:
            EnumsDialogueGenerationType.DialogueGenerationTypeClient,
          personalityId: entry.speakerId,
          script: entry.script,
        };
      }),
    };
    return dialogue;
  }

  static JoinSegments(segments: DialogueScriptSegment[]): string {
    return fromScriptSegments(segments);
  }

  static async FillImage(segment: DialogueMarkerImage) {
    if (segment.name) return segment;
    if (!segment.query) return segment;

    const media = await DialogueUtils.GenerateAndUploadImage(segment.query);
    return {
      ...segment,
      name: media.id,
    };
  }

  static async FillScriptImages(script: string): Promise<string> {
    const segments = fromString(script);
    const filledSegments = await Promise.all(
      segments.map((segment) => {
        if (segment.type === 'image') {
          return this.FillImage(segment);
        }
        return segment;
      })
    );
    return DialogueUtils.JoinSegments(filledSegments);
  }

  static async FillDialogueImages(
    dialogue: ModelsDialogue
  ): Promise<ModelsDialogue> {
    await Promise.all(
      dialogue.entries.map(async (entry) => {
        const script = await this.FillScriptImages(entry.script);
        entry.script = script;
      })
    );

    return dialogue;
  }

  /**
   * Generate an image using AI and upload it to the media server
   * @param query The text description to generate the image from
   * @returns The uploaded media object
   */
  static async GenerateAndUploadImage(query: string): Promise<Media> {
    try {
      // Generate image using AI
      const genResponse = await apiService.aiGeneral.generateImages({
        prompt: query,
        num: 1,
        aspectRatio: ClientAspectRatio.ASPECTRATIO_WIDE,
      });

      if (genResponse.data.images.length === 0) {
        throw new Error('No images generated');
      }

      // Convert base64 to blob
      const b64Data = genResponse.data.images[0].b64;
      const byteCharacters = atob(b64Data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'image/png' });

      // Upload the generated image
      const uploadResponse = await apiService.media.upload(blob, {
        contentType: 'image/png',
      });

      return uploadResponse.data.media;
    } catch (error) {
      console.error('Failed to generate or upload image:', error);
      throw error;
    }
  }

  static EnsurePersonalities(
    dialogue: ModelsDialogue,
    personalitiesMap: Map<string, DtoPersonality>,
    defaultPersonalityId: string
  ): ModelsDialogue {
    for (const entry of dialogue.entries) {
      if (personalitiesMap.get(entry.personalityId)) continue;
      entry.personalityId = defaultPersonalityId;
    }
    return dialogue;
  }

  static IsDialogueEntryEqual(
    a: ModelsDialogueEntry,
    b: ModelsDialogueEntry
  ): boolean {
    return isEqualWith(a, b, (_va, _vb, key) => {
      if (key === 'id') {
        return true;
      }
      return undefined;
    });
  }

  static IsDialogueEqual(a: ModelsDialogue, b: ModelsDialogue): boolean {
    if (a.entries.length !== b.entries.length) {
      return false;
    }

    for (let i = 0; i < a.entries.length; i++) {
      if (!DialogueUtils.IsDialogueEntryEqual(a.entries[i], b.entries[i])) {
        return false;
      }
    }

    return true;
  }
}
