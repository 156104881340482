import { useEffectOnce } from 'react-use';
import { v4 as uuidv4 } from 'uuid';
import { proxy } from 'valtio';

import {
  type DtoTTSRenderRequest,
  EnumsHiddenPictureMode,
  EnumsTTSCacheControl,
  EnumsTTSRenderPolicy,
} from '@lp-lib/api-service-client/public';
import {
  type HiddenPictureBlock,
  HotSpotShape,
  type HotSpotV2,
} from '@lp-lib/game';
import { type BlockOutputsDesc } from '@lp-lib/game/src/block-outputs';
import { type Logger } from '@lp-lib/logger-base';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { apiService } from '../../../../services/api-service';
import { sleep } from '../../../../utils/common';
import {
  ImagePickPriorityHighToLow,
  MediaUtils,
} from '../../../../utils/media';
import { markSnapshottable, useSnapshot } from '../../../../utils/valtio';
import { getStingerPrompt } from '../../apis/StingerControl';
import { BlockContainer } from '../../design/BlockContainer';
import { CommonButton } from '../../design/Button';
import {
  type BlockDependencies,
  type IBlockCtrl,
  type PlaygroundPlaybackProtocol,
} from '../../types';
import { getOutputSchema } from './outputs';

type State = {
  status: 'init' | 'present' | 'play' | 'inform' | 'complete';
  visibility: 'visible' | 'hidden';
  instructions: string;
  mediaUrl: string;
  cta: 'reveal' | 'continue';
  numRemaining: number;
  foundHotspots: string[];
  revealed: boolean;
  misses: Miss[];
  revealedHotspots: string[];
};

type Miss = {
  id: string;
  x: number;
  y: number;
};

export class HiddenPictureBlockControlAPI implements IBlockCtrl {
  private _state = markSnapshottable(
    proxy<State>({
      status: 'init',
      visibility: 'hidden',
      instructions: '',
      mediaUrl: '',
      cta: 'reveal',
      numRemaining: 0,
      foundHotspots: [],
      revealed: false,
      misses: [],
      revealedHotspots: [],
    })
  );
  private totalMisses = 0;
  private resolvedTTS: {
    stinger: Nullable<DtoTTSRenderRequest>;
    instructions: Nullable<DtoTTSRenderRequest>;
    completion: Nullable<DtoTTSRenderRequest>;
  } = {
    stinger: null,
    instructions: null,
    completion: null,
  };
  private delegate: Nullable<PlaygroundPlaybackProtocol>;
  private logger: Logger;
  private _hotspots: HotSpotV2[] = [];
  // TODO(jose): revise HiddenPictureBlock typesafe implementation
  private schema: BlockOutputsDesc;

  mode: EnumsHiddenPictureMode = EnumsHiddenPictureMode.HiddenPictureModeAll;

  constructor(
    private block: HiddenPictureBlock,
    private deps: BlockDependencies
  ) {
    this.logger = deps.getLogger('hidden-picture-block');
    this.schema = getOutputSchema(block) as BlockOutputsDesc;

    const first = block.fields.pictures?.at(0);
    if (!first) {
      // TODO: handle this better.
      return;
    }

    this.mode = first.mode ?? EnumsHiddenPictureMode.HiddenPictureModeAll;

    this._state.instructions =
      first.question || block.fields.instructions || '';
    this._state.mediaUrl =
      MediaUtils.PickMediaUrl(first.mainMedia, {
        priority: ImagePickPriorityHighToLow,
      }) ?? '';
    this._state.numRemaining = first.hotSpotsV2?.length ?? 0;
    this._hotspots = first.hotSpotsV2 ?? [];
  }

  get state() {
    return this._state;
  }

  get hotspots() {
    return this._hotspots;
  }

  async preload() {
    if (!this.block.fields.personalityId) return;
    this.resolvedTTS.stinger = await this.stingerTTS();
    this.resolvedTTS.instructions = await this.instructionsTTS();

    await this.deps.lvoLocalCacheWarm(this.resolvedTTS.stinger);
    await this.deps.lvoLocalCacheWarm(this.resolvedTTS.instructions);
  }

  async initialize(preloaded: Promise<void>) {
    await preloaded;
  }

  setDelegate(delegate: PlaygroundPlaybackProtocol) {
    this.delegate = delegate;
  }

  async present() {
    try {
      await this.deps.stingerControl.playBlockIntro(
        this.block,
        this.resolvedTTS.stinger
      );
    } catch (e) {
      this.logger.error('failed to play stinger TTS', e);
    }

    try {
      const player = this.deps.createLVOLocalPlayer(
        this.resolvedTTS.instructions
      );
      const info = await player.playFromPool();
      await info?.trackStarted;
      this._state.visibility = 'visible';
      this._state.status = 'present';
      await info?.trackEnded;
    } catch (e) {
      this.logger.error(
        'failed to play question TTS, falling back to silence',
        e
      );
      this._state.visibility = 'visible';
      this._state.status = 'present';
      await sleep(3000);
    }
    this._state.status = 'play';
  }

  async dropPin(x: number, y: number) {
    if (this._state.status !== 'play') return;

    const hitHotspots = this._hotspots.filter((hotspot) => {
      if (this._state.foundHotspots.includes(hotspot.id)) return false;
      return this.isPointInHotspot(x, y, hotspot);
    });

    if (hitHotspots.length === 0) {
      if (
        this._state.numRemaining > 0 &&
        this.mode !== EnumsHiddenPictureMode.HiddenPictureModeOne
      ) {
        this.deps.sfxControl.play('rapidWrong');
        const missId = uuidv4();
        this._state.misses.push({ id: missId, x, y });
        this.totalMisses += 1;

        setTimeout(() => {
          const index = this._state.misses.findIndex(
            (miss) => miss.id === missId
          );
          if (index !== -1) {
            this._state.misses.splice(index, 1);
          }
        }, 2000);
      }
      return;
    }

    for (const hotspot of hitHotspots) {
      this._state.foundHotspots.push(hotspot.id);
      this._state.numRemaining -= 1;
      this.delegate?.blockDidOutput(this.schema[hotspot.id], 'found');
    }

    if (this.mode === EnumsHiddenPictureMode.HiddenPictureModeOne) {
      this._state.status = 'complete';
      await this.end();
      return;
    }

    this.deps.sfxControl.play('rapidCorrect');
    if (this._state.numRemaining <= 0) {
      this._state.status = 'inform';
      if (!this.deps.isAssessing()) {
        try {
          const completion = await this.completionTTS(
            this._state.numRemaining,
            this._state.foundHotspots.length
          );
          const player = this.deps.createLVOLocalPlayer(completion);
          const info = await player.playFromPool();
          await info?.trackEnded;
        } catch (e) {
          this.logger.error('failed to play result TTS', e);
        }
      }
      this._state.status = 'complete';
      this._state.cta = 'continue';
    }
  }

  private isPointInHotspot(x: number, y: number, hotspot: HotSpotV2): boolean {
    const shape = hotspot.shape;
    const data = hotspot.shapeData;
    if (shape === HotSpotShape.Circle && data.circle) {
      const circle = data.circle;
      const ASPECT_RATIO = 16 / 9;
      const centerX = circle.left + circle.radius / ASPECT_RATIO;
      const centerY = circle.top + circle.radius;
      const dx = x - centerX;
      const dy = y - centerY;
      const normalizedX = dx / (circle.radius / ASPECT_RATIO);
      const normalizedY = dy / circle.radius;
      const distance = normalizedX * normalizedX + normalizedY * normalizedY;
      return distance <= 1;
    } else if (shape === HotSpotShape.Rectangle && data.rectangle) {
      const rect = data.rectangle;
      return (
        x >= rect.left &&
        x <= rect.left + rect.width &&
        y >= rect.top &&
        y <= rect.top + rect.height
      );
    } else {
      return false;
    }
  }

  async reveal() {
    this._state.revealed = true;

    const unfoundHotspots = this._hotspots
      .filter((h) => !this._state.foundHotspots.includes(h.id))
      .map((h) => h.id);

    this._state.revealedHotspots = unfoundHotspots;

    this._state.status = 'inform';
    if (!this.deps.isAssessing()) {
      try {
        const completion = await this.completionTTS(
          this._state.numRemaining,
          this._state.foundHotspots.length
        );
        const player = this.deps.createLVOLocalPlayer(completion);
        const info = await player.playFromPool();
        await info?.trackEnded;
      } catch (e) {
        this.logger.error('failed to play result TTS', e);
      }
    }
    this._state.status = 'complete';
    this._state.cta = 'continue';
  }

  async continueToEnd() {
    this.deps.sfxControl.play('instructionHoverReadyButton');
    await this.end();
  }

  private async end() {
    this.delegate?.blockDidOutput(
      this.schema.question,
      this.block.fields.instructions || ''
    );
    this.delegate?.blockDidOutput(
      this.schema.spotsCount,
      this._hotspots.length
    );
    this.delegate?.blockDidOutput(
      this.schema.foundCount,
      this._state.foundHotspots.length
    );
    let totalPoints = 0;
    let points = 0;
    for (const hotspot of this._hotspots) {
      totalPoints += hotspot.points;
      if (this._state.foundHotspots.includes(hotspot.id)) {
        points += hotspot.points;
      }
    }
    this.delegate?.blockDidOutput(this.schema.points, points);
    this.delegate?.blockDidOutput(this.schema.totalPoints, totalPoints);
    await this.emitUnfoundHotspots();
    await this.delegate?.blockDidEnd();
  }

  async destroy() {
    return;
  }

  private async emitUnfoundHotspots() {
    if (!this.delegate) return;
    const promises: Promise<void>[] = [];
    for (const hotspot of this._hotspots) {
      if (!this._state.foundHotspots.includes(hotspot.id)) {
        promises.push(
          this.delegate.blockDidOutput(this.schema[hotspot.id], 'not-found')
        );
      }
    }
    return Promise.all(promises);
  }

  private async stingerTTS(): Promise<Nullable<DtoTTSRenderRequest>> {
    if (!this.deps.stingerControl.shouldPreloadTTS(this.block)) return null;

    return this.makeTTSRenderRequest(
      getStingerPrompt(
        this.state.instructions,
        'Hidden Picture',
        'Find hidden items in the image.'
      )
    );
  }

  private async completionTTS(
    numRemaining: number,
    totalFound: number
  ): Promise<Nullable<DtoTTSRenderRequest>> {
    return this.makeTTSRenderRequest(
      await this.getEvaluationScript(numRemaining, totalFound)
    );
  }

  private async instructionsTTS() {
    return this.makeTTSRenderRequest(this.state.instructions);
  }

  private async makeTTSRenderRequest(
    script: Nullable<string>
  ): Promise<Nullable<DtoTTSRenderRequest>> {
    if (!script) return null;

    const personalityId = this.block.fields.personalityId;
    if (!personalityId) return null;

    const resolved = await this.deps.commonVariableRegistry.render(script);
    return {
      script: resolved.script,
      personalityId,
      cacheControl: EnumsTTSCacheControl.TTSCacheControlShortLive,
      policy: EnumsTTSRenderPolicy.TTSRenderPolicyReadThrough,
    };
  }

  private async getEvaluationScript(
    numRemaining: number,
    totalFound: number
  ): Promise<Nullable<string>> {
    if (!this.block.fields.personalityId) return null;

    try {
      const resp = await apiService.promptTemplate.runTemplate({
        promptTemplateMappingKey: 'hidden-picture/evaluation-script',
        variables: {
          context:
            this.block.fields.instructions || 'Find all the hidden pictures',
          totalRemaining: numRemaining,
          totalFound: totalFound,
          totalMisses: this.totalMisses,
        },
      });
      return resp.data.content;
    } catch (e) {
      this.logger.error('failed to generate evaluation script', e);
      return null;
    }
  }
}

function HotSpots({ ctrl }: { ctrl: HiddenPictureBlockControlAPI }) {
  const { foundHotspots, revealed, revealedHotspots } = useSnapshot(ctrl.state);
  const hotspots = ctrl.hotspots;

  const hotspotsToDisplay = revealed
    ? hotspots
    : hotspots.filter((h) => foundHotspots.includes(h.id));

  return (
    <>
      {hotspotsToDisplay.map((hotspot: HotSpotV2) => {
        const { shape, shapeData, id } = hotspot;

        let backgroundColor = 'rgba(0, 255, 0, 0.2)';
        let borderColor = 'lightgreen';

        if (
          !foundHotspots.includes(id) &&
          revealed &&
          revealedHotspots.includes(id)
        ) {
          // This hotspot was revealed, not found by the user
          backgroundColor = 'rgba(255, 120, 0, 0.2)'; // yellowish
          borderColor = 'gold';
        }

        if (shape === HotSpotShape.Circle && shapeData.circle) {
          const circle = shapeData.circle;
          const style = {
            position: 'absolute' as const,
            left: `${circle.left * 100}%`,
            top: `${circle.top * 100}%`,
            height: `${circle.radius * 2 * 100}%`,
            width: 'auto',
            aspectRatio: '1/1',
            backgroundColor: backgroundColor,
            border: `1px solid ${borderColor}`,
            borderRadius: '50%',
            pointerEvents: 'none' as const,
          } as React.CSSProperties;
          return (
            <div
              key={id}
              style={style}
              className='animate-fade-in-v2 shadow-xl'
            />
          );
        } else if (shape === HotSpotShape.Rectangle && shapeData.rectangle) {
          const rect = shapeData.rectangle;
          const style = {
            position: 'absolute' as const,
            left: `${rect.left * 100}%`,
            top: `${rect.top * 100}%`,
            width: `${rect.width * 100}%`,
            height: `${rect.height * 100}%`,
            backgroundColor: backgroundColor,
            border: `1px solid ${borderColor}`,
            pointerEvents: 'none' as const,
          };
          return <div key={id} style={style} />;
        } else {
          return null;
        }
      })}
    </>
  );
}

function Misses({ ctrl }: { ctrl: HiddenPictureBlockControlAPI }) {
  const { misses } = useSnapshot(ctrl.state);
  return (
    <>
      {misses.map((miss: Miss) => {
        const style = {
          position: 'absolute' as const,
          left: `${miss.x * 100}%`,
          top: `${miss.y * 100}%`,
          transform: 'translate(-50%, -50%)',
          pointerEvents: 'none' as const,
          '--tw-fade-out-v2-duration': '2s',
        };
        return (
          <div key={miss.id} style={style} className='animate-fade-out-v2'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='w-8 h-8 text-red-600'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
            >
              <line
                x1='18'
                y1='6'
                x2='6'
                y2='18'
                strokeWidth='2'
                strokeLinecap='round'
              />
              <line
                x1='6'
                y1='6'
                x2='18'
                y2='18'
                strokeWidth='2'
                strokeLinecap='round'
              />
            </svg>
          </div>
        );
      })}
    </>
  );
}

function NumRemainingIndicator({
  ctrl,
}: {
  ctrl: HiddenPictureBlockControlAPI;
}) {
  const { numRemaining } = useSnapshot(ctrl.state);

  return (
    <div className='flex-none text-white text-center self-auto md:self-end md:mr-2 mt-20 md:mt-2 select-none'>
      <span
        key={numRemaining} // Key forces remount and animation restart
        className={`text-4xl font-bold italic animate-count-change ${
          numRemaining > 0 ? 'text-green-001' : 'text-[#4B4B4B]'
        }`}
      >
        {numRemaining}&nbsp;
      </span>
      remaining
    </div>
  );
}

export function HiddenPictureBlockPlayground(props: {
  block: HiddenPictureBlock;
  ctrl: HiddenPictureBlockControlAPI;
}) {
  const { status, visibility, instructions, mediaUrl, cta, numRemaining } =
    useSnapshot(props.ctrl.state);

  const isNotOnePictureMode =
    props.ctrl.mode !== EnumsHiddenPictureMode.HiddenPictureModeOne;

  useEffectOnce(() => {
    props.ctrl.present();
  });

  const handleClick = useLiveCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (props.ctrl.state.status !== 'play') return;

      const rect = event.currentTarget.getBoundingClientRect();
      const x = (event.clientX - rect.left) / rect.width;
      const y = (event.clientY - rect.top) / rect.height;
      props.ctrl.dropPin(x, y);
    }
  );

  return (
    <BlockContainer className='flex flex-col'>
      <main
        className={`
          w-full flex-1 min-h-0 flex flex-col
          transition-opacity duration-500 ${
            visibility === 'visible' ? 'opacity-100' : 'opacity-0'
          }
        `}
      >
        <div className='flex-none pt-4 px-10 italic text-white text-center break-words text-base sm:text-xl md:text-2xl lg:text-3xl'>
          {instructions}
        </div>
        <div className='flex-1 flex flex-col'>
          <div className='flex-1 flex flex-col items-center justify-center'>
            <div
              className={`relative w-full aspect-w-16 aspect-h-9 md:mt-0 ${
                isNotOnePictureMode ? 'mt-20' : ''
              }`}
            >
              <img
                src={mediaUrl}
                alt=''
                className='w-full h-full scale-x-150 scale-y-125 blur opacity-70 md:hidden select-none'
              />
              <img src={mediaUrl} alt='' className='w-full h-full' />
              <div
                className='absolute inset-0 z-55 hover:cursor-pointer'
                onClick={handleClick}
              >
                <HotSpots ctrl={props.ctrl} />
                <Misses ctrl={props.ctrl} />
              </div>
            </div>
            {isNotOnePictureMode && <NumRemainingIndicator ctrl={props.ctrl} />}
          </div>
        </div>
        <div
          className={`${
            status === 'complete' ? 'opacity-100' : 'opacity-0'
          } text-center`}
        >
          {numRemaining === 0 ? (
            <div className='text-green-001 text-sms'>
              <span className='font-bold'>
                {props.ctrl.hotspots.length} Correct!
              </span>
              &nbsp; Nice Job!
            </div>
          ) : (
            <div className='text-yellow-001 text-sms'>
              You found {props.ctrl.state.foundHotspots.length}/
              {props.ctrl.hotspots.length} items
            </div>
          )}
        </div>
      </main>

      <footer
        className={`
          w-full px-3 pt-3 pb-5 flex justify-center relative
          transition-opacity duration-500  ${
            visibility === 'visible' ? 'opacity-100' : 'opacity-0'
          }
              ${
                props.ctrl.mode === EnumsHiddenPictureMode.HiddenPictureModeOne
                  ? 'hidden'
                  : ''
              }
        `}
      >
        {cta === 'reveal' ? (
          <CommonButton
            variant='gray'
            onClick={() => props.ctrl.reveal()}
            disabled={status === 'inform'}
          >
            Reveal Answers
          </CommonButton>
        ) : (
          <CommonButton
            variant='correct'
            onClick={() => props.ctrl.continueToEnd()}
            className={
              status === 'inform' || status === 'complete'
                ? 'opacity-100'
                : 'opacity-0 pointer-events-off'
            }
            disabled={status === 'inform'}
          >
            Continue
          </CommonButton>
        )}
      </footer>
    </BlockContainer>
  );
}
