import shuffle from 'lodash/shuffle';
import { useEffect, useState } from 'react';
import { match } from 'ts-pattern';
import useFitText from 'use-fit-text';
import { proxy, useSnapshot } from 'valtio';

import {
  type DtoTTSRenderRequest,
  EnumsFillInTheBlanksSegmentType,
  EnumsTTSCacheControl,
  EnumsTTSRenderPolicy,
} from '@lp-lib/api-service-client/public';
import { type FillInTheBlanksBlock } from '@lp-lib/game';
import type { Logger } from '@lp-lib/logger-base';

import { sleep } from '../../../../utils/common';
import {
  markSnapshottable,
  type ValtioSnapshottable,
} from '../../../../utils/valtio';
import { getStingerPrompt } from '../../apis/StingerControl';
import { BlockContainer } from '../../design/BlockContainer';
import { CommonButton } from '../../design/Button';
import { CorrectAnimationWithLayout } from '../../design/CorrectAnimation';
import { SparkBlockBackground } from '../../design/SparkBackground';
import {
  type BlockDependencies,
  type IBlockCtrl,
  type PlaygroundPlaybackProtocol,
} from '../../types';
import {
  type FillInTheBlanksBlockOutputSchema,
  getOutputSchema,
} from './outputs';

type Blank = {
  index: number;
  segmentId: string;
  answer: string;
  appliedOptionIndex: number | null;
  result: 'correct' | 'incorrect' | null;
};

type Option = {
  index: number;
  text: string;
  sizeOrder: number;
  appliedBlankIndex: number | null;
};

type GameState = {
  status: 'init' | 'present' | 'play' | 'inform' | 'complete';
  blanks: Blank[];
  options: Option[];
  blankIndex: number;
  correctCount: number;
  visible: boolean;
};

export class FIBChoiceControlAPI implements IBlockCtrl {
  private _state: ValtioSnapshottable<GameState>;

  private delegate: Nullable<PlaygroundPlaybackProtocol>;
  private schema: FillInTheBlanksBlockOutputSchema;
  private logger: Logger;
  private resolvedTTS: {
    stinger: Nullable<DtoTTSRenderRequest>;
  } = {
    stinger: null,
  };

  constructor(
    private block: FillInTheBlanksBlock,
    private deps: BlockDependencies
  ) {
    this.logger = deps.getLogger('fib-choice');

    const blanks: Blank[] = block.fields.segments
      .filter(
        (s) =>
          s.type ===
          EnumsFillInTheBlanksSegmentType.FillInTheBlanksSegmentTypeBlank
      )
      .map((s, index) => ({
        index,
        segmentId: s.id,
        answer: s.text,
        appliedOptionIndex: null,
        result: null,
      }));

    const candidates = [
      ...blanks.map((b) => b.answer),
      ...block.fields.wrongAnswers,
    ]
      .slice(0, 4)
      .sort((a, b) => a.length - b.length);
    const options: Option[] = candidates.map((text, index) => ({
      index,
      text,
      sizeOrder: index,
      appliedBlankIndex: null,
    }));
    // shuffle options
    shuffle(options);
    for (let i = 0; i < options.length; i++) {
      options[i].index = i;
    }

    this._state = markSnapshottable(
      proxy<GameState>({
        status: 'init',
        blanks,
        options,
        blankIndex: 0,
        correctCount: 0,
        visible: false,
      })
    );

    this.schema = getOutputSchema(block);
  }

  get state() {
    return this._state;
  }

  async preload() {
    if (this.deps.stingerControl.shouldPreloadTTS(this.block)) {
      const question = this.block.fields.segments
        .map((s) =>
          s.type ===
          EnumsFillInTheBlanksSegmentType.FillInTheBlanksSegmentTypeBlank
            ? '___'
            : s.text
        )
        .join(' ');

      this.resolvedTTS.stinger = await this.preloadTTS(
        getStingerPrompt(
          question,
          'Fill in the Blanks',
          'The learner must fill in the blanks with the missing content.'
        )
      );
    }
    return;
  }

  async initialize(preloaded: Promise<void>) {
    await preloaded;
  }

  setDelegate(delegate: PlaygroundPlaybackProtocol) {
    this.delegate = delegate;
  }

  private updateTutorForBlockStatus(status: GameState['status']) {
    switch (status) {
      case 'play':
      case 'complete':
        this.deps.tutorControl.setAvailabilityState('available');
        break;
      case 'inform':
        this.deps.tutorControl.setAvailabilityState('unavailable');
        break;
      default:
        this.deps.tutorControl.setAvailabilityState('unavailable');
    }
  }

  async present() {
    try {
      await this.deps.stingerControl.playBlockIntro(
        this.block,
        this.resolvedTTS.stinger
      );
    } catch (e) {
      this.logger.error('failed to play stinger TTS', e);
    }

    this._state.status = 'present';
    this.updateTutorForBlockStatus('present');
    this._state.visible = true;

    this._state.status = 'play';
    this.updateTutorForBlockStatus('play');
  }

  async applyOption(optionIndex: number) {
    const blank = this.state.blanks[this.state.blankIndex];
    const option = this.state.options[optionIndex];

    // option already applied
    if (option.appliedBlankIndex !== null) {
      return;
    }
    // blank already applied
    if (blank.appliedOptionIndex !== null) {
      return;
    }

    blank.appliedOptionIndex = option.index;
    option.appliedBlankIndex = blank.index;

    blank.result = blank.answer === option.text ? 'correct' : 'incorrect';

    this.state.status = 'inform';
    this.updateTutorForBlockStatus('inform');
    if (blank.result === 'correct') {
      this._state.correctCount += 1;
      this.deps.sfxControl.play('rapidCorrect');
      this.deps.shakeControl.rootPop();
    } else {
      this.deps.sfxControl.play('rapidWrong');
      this.deps.shakeControl.rootIncorrect();
    }
    await sleep(1000);

    if (this.state.blanks.some((b) => !b.result)) {
      this.nextBlank();
      this.state.status = 'play';
      this.updateTutorForBlockStatus('play');
    } else {
      this.state.status = 'complete';
      this.updateTutorForBlockStatus('complete');
    }

    if (blank.result === 'incorrect') {
      option.appliedBlankIndex = null;

      const correctOption = this.state.options.find(
        (o) => o.text === blank.answer && o.appliedBlankIndex === null
      );
      if (correctOption) {
        correctOption.appliedBlankIndex = blank.index;
        blank.appliedOptionIndex = correctOption.index;
        blank.result = 'correct';
      }
    }
  }

  nextBlank() {
    this.state.blankIndex =
      (this.state.blankIndex + 1) % this.state.blanks.length;
  }

  setBlankIndex(index: number) {
    this.state.blankIndex = index;
  }

  async end() {
    this.delegate?.blockDidOutput(
      this.schema.sentence,
      this.block.fields.segments.map((s) => s.text).join('')
    );
    this.delegate?.blockDidOutput(
      this.schema.blanksCount,
      this.state.blanks.length
    );
    this.delegate?.blockDidOutput(
      this.schema.correctCount,
      this.state.correctCount
    );
    this.delegate?.blockDidOutput(
      this.schema.points,
      this.state.correctCount * this.block.fields.pointsPerCorrect
    );
    this.delegate?.blockDidOutput(
      this.schema.totalPoints,
      this.state.blanks.length * this.block.fields.pointsPerCorrect
    );
    await this.delegate?.blockDidEnd();
  }

  async destroy() {
    return;
  }

  private async preloadTTS(script: string) {
    const req = await this.makeTTSRenderRequest(script);
    if (!req) return null;
    await this.deps.lvoLocalCacheWarm(req);
    return req;
  }

  private async makeTTSRenderRequest(
    script: Nullable<string>,
    preferFlashRender = false
  ): Promise<Nullable<DtoTTSRenderRequest>> {
    if (!script) return null;

    const personalityId = this.block.fields.personalityId;
    if (!personalityId) return null;

    const resolved = await this.deps.commonVariableRegistry.render(script);
    return {
      script: resolved.script,
      personalityId,
      cacheControl: EnumsTTSCacheControl.TTSCacheControlShortLive,
      policy: EnumsTTSRenderPolicy.TTSRenderPolicyReadThrough,
      preferFlashRender,
    };
  }
}

function OptionButton(props: {
  isPlaying: boolean;
  option: Option;
  activeBlank: Blank | null;
  appliedBlank: Blank | null;
  ctrl: FIBChoiceControlAPI;
}) {
  const { isPlaying, option, activeBlank, appliedBlank, ctrl } = props;
  const { fontSize, ref } = useFitText({ logLevel: 'none' });
  const [isHovered, setIsHovered] = useState(false);

  let backgroundColor = '#0029AE';
  if (appliedBlank?.result === 'correct') {
    backgroundColor = '#39D966';
  } else if (appliedBlank?.result === 'incorrect') {
    backgroundColor = '#FF0935';
  } else if (
    activeBlank?.result === 'incorrect' &&
    activeBlank.answer === option.text
  ) {
    backgroundColor = '#39D966';
  }

  const appliedBefore =
    option.appliedBlankIndex !== null &&
    option.appliedBlankIndex !== activeBlank?.index;
  const disabled = !isPlaying || option.appliedBlankIndex !== null;

  return (
    <button
      type='button'
      className={`
        relative rounded-full
        transition-all duration-500
        ${appliedBefore ? 'opacity-40' : ''}
        ${disabled ? 'cursor-default' : 'hover:scale-110 active:scale-80'}
        ${match(option.sizeOrder)
          .with(0, () => 'w-16 h-16 lg:w-17 lg:h-17')
          .with(1, () => 'w-18 h-18 lg:w-22 lg:h-22')
          .with(2, () => 'w-25 h-25 lg:w-33 lg:h-33')
          .otherwise(() => 'w-30 h-30 lg:w-37 lg:h-37')}
      `}
      style={{
        backgroundColor,
        boxShadow:
          isHovered && !disabled
            ? '20px -40px 40px 0px rgba(0, 0, 0, 0.20) inset'
            : '20px -40px 40px 0px rgba(0, 0, 0, 0.50) inset',
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => ctrl.applyOption(option.index)}
      disabled={option.appliedBlankIndex !== null}
    >
      <div
        className='absolute inset-0 rounded-full'
        style={{
          background:
            'linear-gradient(to bottom, rgba(255,255,255,0) 50%, rgba(255,255,255,0.2) 100%)',
        }}
      ></div>
      <div
        className='absolute inset-0 bottom-[6px] rounded-full transition-all duration-300'
        style={{
          backgroundColor,
          boxShadow:
            isHovered && !disabled
              ? '20px -40px 40px 0px rgba(0, 0, 0, 0.20) inset'
              : '20px -40px 40px 0px rgba(0, 0, 0, 0.50) inset',
        }}
      ></div>
      <div
        ref={ref}
        className='relative w-full h-full flex items-center justify-center p-2'
        style={{ fontSize }}
      >
        <span className='text-[#81BCFF] font-bold'>{props.option.text}</span>
      </div>
    </button>
  );
}

function BlankInput(props: {
  blank: Blank;
  appliedOption: Option | null;
  active?: boolean;
  ctrl: FIBChoiceControlAPI;
}) {
  const { blank, active } = props;

  if (blank.result) {
    return <span className='text-green-001'>{blank.answer}</span>;
  }
  if (active) {
    return (
      <span className='bg-[#FBB707] bg-opacity-40 text-[#FBB707]'>
        ___________
      </span>
    );
  }
  return <span className='text-icon-gray'>___________</span>;
}

export function FIBPlaygroundChoice(props: {
  block: FillInTheBlanksBlock;
  ctrl: FIBChoiceControlAPI;
}) {
  const { ctrl } = props;
  const { status, blanks, options, blankIndex, visible } = useSnapshot(
    props.ctrl.state
  );

  const activeBlank = blanks[blankIndex];

  useEffect(() => {
    if (status === 'init') {
      ctrl.present();
    }
  }, [status, ctrl]);

  return (
    <>
      <SparkBlockBackground block={props.block} />
      <BlockContainer
        className={`flex flex-col gap-5 transition-opacity duration-500 ${
          visible ? 'opacity-100' : 'opacity-0'
        }`}
      >
        <main className='w-full flex-1 min-h-0 px-10 py-5 flex flex-col gap-5 sm:gap-10'>
          <div className='text-center text-sms italic text-icon-gray'>
            Fill in the blanks
          </div>

          <div
            className={`
            text-white text-center break-words
              text-base sm:text-xl lg:text-2xl leading-relaxed
          `}
          >
            {props.block.fields.segments.map((segment) => {
              switch (segment.type) {
                case EnumsFillInTheBlanksSegmentType.FillInTheBlanksSegmentTypeText:
                  return <span key={segment.id}>{segment.text}</span>;
                case EnumsFillInTheBlanksSegmentType.FillInTheBlanksSegmentTypeBlank:
                  const blank = blanks.find(
                    (b) => b.segmentId === segment.id
                  ) as Blank;

                  return (
                    <BlankInput
                      key={blank.segmentId}
                      blank={blank}
                      appliedOption={
                        blank.appliedOptionIndex !== null
                          ? options[blank.appliedOptionIndex]
                          : null
                      }
                      active={blank.index === blankIndex}
                      ctrl={ctrl}
                    />
                  );
                default:
                  return null;
              }
            })}
          </div>

          <div className='grid grid-cols-2 gap-2'>
            {options.map((option) => (
              <div
                key={option.index}
                className={`flex items-center ${
                  option.index % 2 === 0 ? 'justify-end' : 'justify-start'
                }`}
              >
                <OptionButton
                  isPlaying={status === 'play'}
                  option={option}
                  activeBlank={activeBlank}
                  appliedBlank={
                    option.appliedBlankIndex !== null
                      ? blanks[option.appliedBlankIndex]
                      : null
                  }
                  ctrl={ctrl}
                />
              </div>
            ))}
          </div>
        </main>

        <footer
          className={`'w-full flex flex-col items-center gap-2 px-3 pt-3 pb-5 transition-opacity duration-500 relative`}
        >
          <div className='w-full h-5 flex items-center justify-center text-xs text-black text-opacity-80 text-center'>
            {status === 'inform' &&
              match(activeBlank?.result)
                .with('correct', () => (
                  <span className='text-green-001'>
                    <strong>Correct!</strong> Nice job!
                  </span>
                ))
                .with('incorrect', () => (
                  <span className='text-red-006'>
                    <strong>Incorrect!</strong> See correct answer above.
                  </span>
                ))
                .otherwise(() => null)}
          </div>

          {status === 'complete' && (
            <CommonButton variant={'correct'} onClick={() => ctrl.end()}>
              Continue
            </CommonButton>
          )}

          {status === 'inform' && activeBlank?.result === 'correct' && (
            <CorrectAnimationWithLayout onEnd={() => void 0} />
          )}
        </footer>
      </BlockContainer>
    </>
  );
}
