import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSnapshot } from 'valtio';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { getStaticAssetPath } from '../../../../utils/assets';
import { ArrowDownIcon, ArrowUpIcon } from '../../../icons/Arrows';
import { ChatSendIcon } from '../../../icons/Chat/ChatSendIcon';
import { KeyboardIcon } from '../../../icons/KeyboardIcon';
import { MicrophoneIcon } from '../../../icons/MicrophoneIcon';
import { Loading } from '../../../Loading';
import { MicrophoneWithVolumeBar } from '../../../MediaControls/MicrophoneWithVolumeBar';
import { type TutorControl } from './TutorControl';
import { TutorRating } from './TutorRating';

interface TutorOverlayProps {
  ctrl: TutorControl;
}

export function TutorOverlay({ ctrl }: TutorOverlayProps) {
  const state = useSnapshot(ctrl.state);

  if (!ctrl.isEnabled) {
    return null;
  }
  if (state.showRating) {
    return <TutorRating ctrl={ctrl} />;
  }
  if (state.handState === 'lowered') {
    return null;
  }

  return <TutorOverlayInternal ctrl={ctrl} />;
}

const raisedHandSrc = getStaticAssetPath('images/raise-hand-icon-v2.png');

function TutorOverlayInternal({ ctrl }: TutorOverlayProps) {
  const state = useSnapshot(ctrl.state);
  const [isClosing, setIsClosing] = useState(false);
  const backdropRef = useRef<HTMLDivElement>(null);
  const statusBarRef = useRef<HTMLDivElement>(null);

  const handleOpen = useLiveCallback(async () => {
    if (!statusBarRef.current) return;
    const a = statusBarRef.current.animate(
      [
        {
          transform: 'translateY(-100%)',
        },
        {
          transform: 'translateY(0)',
        },
      ],
      {
        duration: 200,
        fill: 'forwards',
        easing: 'ease-out',
      }
    );
    const b = backdropRef.current?.animate(
      [{ opacity: '0' }, { opacity: '100%' }],
      {
        duration: 200,
        fill: 'forwards',
        easing: 'ease-out',
      }
    );
    await Promise.all([a.finished, b?.finished]);
  });

  const handleClose = useLiveCallback(async () => {
    if (!statusBarRef.current || isClosing) return;
    setIsClosing(true);
    const a = statusBarRef.current.animate(
      [
        {
          transform: 'translateY(0)',
        },
        {
          transform: 'translateY(-100%)',
        },
      ],
      {
        duration: 200,
        fill: 'forwards',
        easing: 'ease-out',
      }
    );
    const b = backdropRef.current?.animate(
      [{ opacity: '100%' }, { opacity: '0' }],
      {
        duration: 200,
        fill: 'forwards',
        easing: 'ease-out',
      }
    );
    try {
      await Promise.all([a.finished, b?.finished]);
    } finally {
      ctrl.disconnect();
      setIsClosing(false);
    }
  });

  useEffect(() => {
    handleOpen();
  }, [handleOpen]);

  return (
    <div className='fixed inset-0 z-50'>
      <div
        ref={backdropRef}
        className='absolute inset-0 w-full h-full bg-black/20'
      />
      <div className='absolute inset-0 max-w-240 mx-auto flex flex-col gap-3'>
        <div ref={statusBarRef} className='flex-none p-3 pb-0 z-20'>
          <div className='w-full h-[62px] p-px rounded-full bg-gradient-to-b from-[#4CE3B3] to-black'>
            <div className='relative w-full h-full flex items-center justify-between px-4 py-3 bg-main-layer rounded-full'>
              <div className='w-9'>
                <img
                  src={raisedHandSrc}
                  alt='Raised hand'
                  className='w-7 h-7'
                />
              </div>

              <div className='flex flex-col text-center'>
                {state.connectionState === 'connecting' ? (
                  <span className='text-white text-sm font-medium'>
                    Connecting...
                  </span>
                ) : state.availabilityState === 'unavailable' ? (
                  <>
                    <span className='text-white text-sm font-medium'>
                      Your hand is raised
                    </span>
                    <span className='text-[#4CE3B3] text-xs'>
                      The tutor will check in soon
                    </span>
                  </>
                ) : (
                  <>
                    <span className='text-white text-sm font-medium'>
                      AI Tutor
                    </span>
                    <span className='text-[#4CE3B3] text-xs'>
                      <TutorState ctrl={ctrl} />
                    </span>
                  </>
                )}
              </div>

              <div className='flex items-center gap-4'>
                <button
                  type='button'
                  onClick={handleClose}
                  className='text-red-500 bg-red-500/30 p-3 rounded-full group'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 256 256'
                    className='w-3 h-3 fill-current group-hover:scale-110 transition-transform'
                  >
                    <rect width='256' height='256' />
                  </svg>
                </button>
              </div>

              {state.contentDesc && (
                <button
                  type='button'
                  onClick={() => ctrl.toggleContentExpanded()}
                  className='absolute bottom-0 left-1/2 transform -translate-x-1/2 text-white/70 hover:text-white transition-colors'
                >
                  {state.contentExpanded ? <ArrowUpIcon /> : <ArrowDownIcon />}
                </button>
              )}
            </div>
          </div>
        </div>

        {state.connectionState === 'connected' && (
          <>
            <div className='relative flex-1 min-h-0 max-w-240 mx-auto w-full px-3'>
              <ContentBody ctrl={ctrl} />
            </div>

            <div className='relative flex-none min-h-0 max-w-240 mx-auto w-full px-3 z-20'>
              <CommunicationInput ctrl={ctrl} isClosing={isClosing} />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

function TutorState({ ctrl }: TutorOverlayProps) {
  const { tutorState } = useSnapshot(ctrl.state);
  switch (tutorState) {
    case 'speaking':
      return 'is speaking...';
    case 'waiting':
      return 'is waiting...';
    case 'listening':
      return 'is listening...';
    case 'thinking':
      return 'is thinking...';
  }
}

function ContentBody({ ctrl }: TutorOverlayProps) {
  const state = useSnapshot(ctrl.state);
  return (
    <>
      <div
        className={`
          fixed inset-0 bg-black/70 z-10 transition-opacity
          ${
            state.contentExpanded
              ? 'opacity-100'
              : 'opacity-0 pointer-events-none'
          }
        `}
        style={{
          transitionDuration: '200ms',
        }}
      />

      <div
        className={`
          w-full h-full overflow-y-auto scrollbar
          border border-white/10 bg-main-layer rounded-lg
          flex-1 flex flex-col transition z-20 relative
          ${
            state.contentExpanded
              ? 'opacity-1 scale-100'
              : 'opacity-0 scale-105 pointer-events-none'
          }
        `}
        style={{
          transitionDuration: '200ms',
        }}
      >
        <div className='flex-1 min-h-0 overflow-y-auto scrollbar px-5 py-4'>
          {state.error ? (
            <div className='flex items-center justify-center text-red-400 text-center p-4 text-sm lg:text-base'>
              {state.error}
            </div>
          ) : state.isLoading ? (
            <div className='flex flex-col justify-center items-center p-4 h-full text-icon-gray'>
              <Loading text={state.loadingText} />
            </div>
          ) : state.markdownHTML ? (
            <MarkdownContent ctrl={ctrl} />
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}

function CommunicationInput({
  ctrl,
  isClosing,
}: TutorOverlayProps & { isClosing: boolean }) {
  const state = useSnapshot(ctrl.state);
  const drawerRef = useRef<HTMLDivElement>(null);

  const handleOpen = useLiveCallback(async () => {
    if (!drawerRef.current) return;
    const a = drawerRef.current.animate(
      [
        {
          transform: 'translateY(100%)',
        },
        {
          transform: 'translateY(0)',
        },
      ],
      {
        duration: 200,
        fill: 'forwards',
        easing: 'ease-out',
      }
    );
    await a.finished;
  });

  const handleClose = useLiveCallback(async () => {
    if (!drawerRef.current) return;
    const a = drawerRef.current.animate(
      [
        {
          transform: 'translateY(0)',
        },
        {
          transform: 'translateY(100%)',
        },
      ],
      {
        duration: 200,
        fill: 'forwards',
        easing: 'ease-out',
      }
    );
    await a.finished;
  });

  useEffect(() => {
    handleOpen();
  }, [handleOpen]);

  useEffect(() => {
    if (isClosing) handleClose();
  }, [handleClose, isClosing]);

  return (
    <div
      ref={drawerRef}
      className={`
        relative bottom-0 left-0 right-0
        p-px pb-0 rounded-t-lg bg-gradient-to-b from-[#4CE3B3] to-black
        transition flex flex-col
      `}
    >
      <div className='w-full min-h-0 flex-1 flex flex-col bg-main-layer rounded-t-lg'>
        <div className='flex-none pt-4 text-center text-[#4CE3B3] text-sms'>
          {state.tutorQuestion
            ? 'Question from Tutor'
            : 'What’s your question?'}
          {state.tutorQuestion && (
            <div className='pt-2 text-center text-sms text-white font-bold'>
              {state.tutorQuestion.question}
            </div>
          )}
        </div>

        <div className='flex-1 min-h-[85px] flex items-center p-2'>
          {state.communicationPreference === 'text' ? (
            <TextInput ctrl={ctrl} />
          ) : (
            <VoiceInput ctrl={ctrl} />
          )}
        </div>
      </div>

      {state.communicationPreference === 'text' && (
        <button
          type='button'
          className='absolute top-4 right-4 text-icon-gray hover:scale-110 hover:text-white transition'
          onClick={() => ctrl.setCommunicationPreference('voice')}
        >
          <MicrophoneIcon className='w-4 h-4 fill-current' />
        </button>
      )}
    </div>
  );
}

function VoiceInput({ ctrl }: TutorOverlayProps) {
  const { tutorState, isRecordCooldown, communicationDisabled } = useSnapshot(
    ctrl.state
  );
  const handleClick = useLiveCallback(() => {
    if (ctrl.state.tutorState === 'listening') {
      ctrl.stopRecording();
    } else {
      ctrl.startRecording();
    }
  });

  const listening = tutorState === 'listening';

  return (
    <div className='w-full space-y-2'>
      <div className='flex items-center justify-center gap-10'>
        <div className='flex-none invisible w-8 h-8' />
        <div className='flex-none flex flex-col items-center'>
          <button
            type='button'
            className={`
              w-12 h-12 rounded-full
              flex items-center justify-center
              border transition disabled:opacity-50 disabled:pointer-events-none
              ${
                listening
                  ? 'bg-layer-002 text-white border-secondary'
                  : 'bg-[#4CE3B3] text-black border-[#4CE3B3]'
              }
            `}
            onClick={handleClick}
            disabled={isRecordCooldown || (!listening && communicationDisabled)}
          >
            {listening ? (
              <MicrophoneWithVolumeBar
                processor={ctrl.wavRecorderProcessor}
                meterEnabled={true}
                className='w-6 h-6'
              />
            ) : (
              <MicrophoneIcon className='w-6 h-6 fill-current' />
            )}
          </button>
        </div>

        <button
          type='button'
          className={`
            flex-none w-8 h-8 rounded-full
            flex items-center justify-center
            border border-secondary bg-layer-002 text-white
          `}
          disabled={listening}
          onClick={() => ctrl.setCommunicationPreference('text')}
        >
          <KeyboardIcon />
        </button>
      </div>

      <div
        className={`${
          listening ? 'text-red-001' : 'text-icon-gray'
        } text-center text-2xs transition-colors`}
      >
        Push to {listening ? 'finish' : 'talk'}
      </div>
    </div>
  );
}

function TextInput({ ctrl }: TutorOverlayProps) {
  const { communicationDisabled } = useSnapshot(ctrl.state);
  const [input, setInput] = useState('');
  const handleSubmit = useLiveCallback(() => {
    if (input.length === 0) return;

    ctrl.sendTextInput(input);
    setInput('');
  });

  return (
    <div className='w-full p-1 flex items-center gap-3 rounded-full border border-secondary focus-within:border-white text-white transition-colors'>
      <input
        value={input}
        onChange={(e) => setInput(e.currentTarget.value)}
        type='text'
        className='flex-1 h-full ml-2 text-sm appearance-none outline-none focus:outline-none focus:ring-0 bg-transparent disabled:opacity-50 disabled:pointer-events-none'
        autoFocus
        placeholder='Type your question...'
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSubmit();
          }
        }}
        disabled={communicationDisabled}
      />
      <button
        type='button'
        className={`
          rounded-full flex items-center justify-center border p-2
          ${
            input.length === 0
              ? 'bg-layer-002 text-white border-secondary'
              : 'bg-[#4CE3B3] text-black border-[#4CE3B3]'
          }
          transition-colors
        `}
        disabled={input.length === 0 || communicationDisabled}
        onClick={handleSubmit}
      >
        <ChatSendIcon />
      </button>
    </div>
  );
}

function MarkdownContent({ ctrl }: TutorOverlayProps) {
  const { markdownHTML, imageLookup } = useSnapshot(ctrl.state);
  const markdownRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (markdownRef.current && markdownHTML) {
      markdownRef.current.innerHTML = markdownHTML;
    }
  }, [markdownHTML]);

  useEffect(() => {
    if (!markdownRef.current) return;

    for (const [id, src] of Object.entries(imageLookup)) {
      const img = markdownRef.current.querySelector<HTMLImageElement>(
        `img[data-id='${id}']`
      );
      if (!img) continue;
      img.src = src;
      img.className = 'aspect-video w-full h-auto rounded';
    }
  }, [imageLookup]);

  return <div ref={markdownRef} className='markdown-body text-white' />;
}
